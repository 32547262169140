import React, { Fragment } from "react";
import { graphql } from "gatsby"
// import Img from 'gatsby-image'
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ProjectDetails from "@/components/project-details";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <MenuContextProvider>
    <SearchContextProvider>
      <Layout PageTitle="Project Details Page">
        <HeaderOne />
        {frontmatter.type === 'Completed' ? <PageBanner title="Project Details" name="Project" imgClass='pageBannerProjects'/> : <PageBanner title="Upcoming Project Details" name=" Upcoming Project" imgClass='pageBannerProjects'/>
        }
        <ProjectDetails data={frontmatter} html={html}/>
        <Footer />
      </Layout>
    </SearchContextProvider>
  </MenuContextProvider>
      // <div className="single_blog">
      //   <div className="blog_thumb">
      //     <img src={frontmatter.image} alt="" />
      //   </div>
      //   <div className="blog_headings">
      //     {/* <span className="blog_date">20 nov</span> */}
      //     <h2>{frontmatter.title}</h2>
      //   </div>
      //   <div className="blog_details">
      //     <p>
      //      {frontmatter.description}
      //     </p>
      //   </div>
      // </div>
    
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image
        description
        type
        gallery {
          publicURL
          id
          base
          childImageSharp {
            gatsbyImageData(width: 900, height: 600)
          }
        }
        scheme {
          publicURL
        }
        temp {
          publicURL
        }
      }
    }
  }
`